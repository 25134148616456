import list from 'src/modules/chatLog/list/chatLogListReducers';
import form from 'src/modules/chatLog/form/chatLogFormReducers';
import view from 'src/modules/chatLog/view/chatLogViewReducers';
import destroy from 'src/modules/chatLog/destroy/chatLogDestroyReducers';
import importerReducer from 'src/modules/chatLog/importer/chatLogImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
