import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/chat/importer/chatImporterSelectors';
import ChatService from 'src/modules/chat/chatService';
import fields from 'src/modules/chat/importer/chatImporterFields';
import { i18n } from 'src/i18n';

const chatImporterActions = importerActions(
  'CHAT_IMPORTER',
  selectors,
  ChatService.import,
  fields,
  i18n('entities.chat.importer.fileName'),
);

export default chatImporterActions;