import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/prompt/importer/promptImporterSelectors';
import PromptService from 'src/modules/prompt/promptService';
import fields from 'src/modules/prompt/importer/promptImporterFields';
import { i18n } from 'src/i18n';

const promptImporterActions = importerActions(
  'PROMPT_IMPORTER',
  selectors,
  PromptService.import,
  fields,
  i18n('entities.prompt.importer.fileName'),
);

export default promptImporterActions;