import list from 'src/modules/app/list/appListReducers';
import form from 'src/modules/app/form/appFormReducers';
import view from 'src/modules/app/view/appViewReducers';
import destroy from 'src/modules/app/destroy/appDestroyReducers';
import importerReducer from 'src/modules/app/importer/appImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
