import Permissions from 'src/security/permissions';
import { i18n } from 'src/i18n';
import {
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

const permissions = Permissions.values;

export default [
  {
    path: '/prompt',
    permissionRequired: permissions.promptRead,
    exact: true,
    icon: faChevronRight,
    label: i18n('entities.prompt.menu'),
  },
].filter(Boolean);
