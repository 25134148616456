import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'input',
    label: i18n('entities.chatLog.fields.input'),
    schema: schemas.string(
      i18n('entities.chatLog.fields.input'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'response',
    label: i18n('entities.chatLog.fields.response'),
    schema: schemas.string(
      i18n('entities.chatLog.fields.response'),
      {},
    ),
  },
  {
    name: 'timeUsed',
    label: i18n('entities.chatLog.fields.timeUsed'),
    schema: schemas.integer(
      i18n('entities.chatLog.fields.timeUsed'),
      {},
    ),
  },
  {
    name: 'chat',
    label: i18n('entities.chatLog.fields.chat'),
    schema: schemas.relationToOne(
      i18n('entities.chatLog.fields.chat'),
      {},
    ),
  },
];