import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.chat.fields.id'),
  },
  {
    name: 'prompt',
    label: i18n('entities.chat.fields.prompt'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'parameters',
    label: i18n('entities.chat.fields.parameters'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.chat.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.chat.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
