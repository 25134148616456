import Permissions from 'src/security/permissions';
import config from 'src/config';

const permissions = Permissions.values;

const privateRoutes = [
  {
    path: '/',
    loader: () => import('src/view/dashboard/DashboardPage'),
    permissionRequired: null,
    exact: true,
  },

  // {
  //   path: '/profile',
  //   loader: () => import('src/view/auth/ProfileFormPage'),
  //   permissionRequired: null,
  //   exact: true,
  // },

  {
    path: '/password-change',
    loader: () => import('src/view/auth/PasswordChangeFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/tenant',
    loader: () => import('src/view/tenant/list/TenantListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/new',
    loader: () => import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/:id/edit',
    loader: () => import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },

  config.isPlanEnabled && {
    path: '/billing',
    loader: () => import('src/view/plan/PlanPage'),
    permissionRequired: permissions.planRead,
    exact: true,
  },
  {
    path: '/report',
    loader: () => import('src/view/report/list/ReportListPage'),
    permissionRequired: false,
    exact: true,
  },

  config.isPlanEnabled && {
    path: '/plan/success',
    loader: () => import('src/view/plan/Success'),
    exact: true,
  },
  config.isPlanEnabled && {
    path: '/plan/cancel',
    loader: () => import('src/view/plan/Cancel'),
    exact: true,
  },
  {
    path: '/admin',
    loader: () => import('src/view/user/list/UserPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/user/new',
    loader: () => import('src/view/user/new/UserNewPage'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },

  {
    path: '/user/importer',
    loader: () => import('src/view/user/importer/UserImporterPage'),
    permissionRequired: permissions.userImport,
    exact: true,
  },
  {
    path: '/user/:id/edit',
    loader: () => import('src/view/user/edit/UserEditPage'),
    permissionRequired: permissions.userEdit,
    exact: true,
  },
  {
    path: '/user/:id',
    loader: () => import('src/view/user/view/UserViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/audit-logs',
    loader: () => import('src/view/auditLog/AuditLogPage'),
    permissionRequired: permissions.auditLogRead,
  },

  {
    path: '/settings',
    loader: () => import('src/view/settings/SettingsFormPage'),
    permissionRequired: permissions.settingsEdit,
  },

  {
    path: '/user-settings',
    loader: () => import('src/view/userSettings/list/UserSettingsListPage'),
    permissionRequired: permissions.userSettingsRead,
    exact: true,
  },
  {
    path: '/user-settings/new',
    loader: () => import('src/view/userSettings/form/UserSettingsFormPage'),
    permissionRequired: permissions.userSettingsCreate,
    exact: true,
  },
  {
    path: '/user-settings/importer',
    loader: () =>
      import('src/view/userSettings/importer/UserSettingsImporterPage'),
    permissionRequired: permissions.userSettingsImport,
    exact: true,
  },
  {
    path: '/user-settings/:id/edit',
    loader: () => import('src/view/userSettings/form/UserSettingsFormPage'),
    permissionRequired: permissions.userSettingsEdit,
    exact: true,
  },
  {
    path: '/user-settings/:id',
    loader: () => import('src/view/userSettings/view/UserSettingsViewPage'),
    permissionRequired: permissions.userSettingsRead,
    exact: true,
  },

  {
    path: '/prompt',
    loader: () => import('src/view/prompt/list/PromptListPage'),
    permissionRequired: permissions.promptRead,
    exact: true,
  },
  {
    path: '/prompt/new',
    loader: () => import('src/view/prompt/form/PromptFormPage'),
    permissionRequired: permissions.promptCreate,
    exact: true,
  },
  // {
  //   path: '/prompt/importer',
  //   loader: () => import('src/view/prompt/importer/PromptImporterPage'),
  //   permissionRequired: permissions.promptImport,
  //   exact: true,
  // },
  {
    path: '/prompt/:id/edit',
    loader: () => import('src/view/prompt/form/PromptFormPage'),
    permissionRequired: permissions.promptEdit,
    exact: true,
  },
  {
    path: '/prompt/:id',
    loader: () => import('src/view/prompt/view/PromptViewPage'),
    permissionRequired: permissions.promptRead,
    exact: true,
  },
  {
    path: '/chat/init/:chatid',
    loader: () => import('src/view/chat/form/ChatInitPage'),
    permissionRequired: permissions.chatRead,
    exact: true,
  },
  // {
  //   path: '/chat',
  //   loader: () => import('src/view/chat/list/ChatListPage'),
  //   permissionRequired: permissions.chatRead,
  //   exact: true,
  // },
  // {
  //   path: '/chat/new',
  //   loader: () => import('src/view/chat/form/ChatFormPage'),
  //   permissionRequired: permissions.chatCreate,
  //   exact: true,
  // },
  // {
  //   path: '/chat/importer',
  //   loader: () => import('src/view/chat/importer/ChatImporterPage'),
  //   permissionRequired: permissions.chatImport,
  //   exact: true,
  // },
  // {
  //   path: '/chat/:id/edit',
  //   loader: () => import('src/view/chat/form/ChatFormPage'),
  //   permissionRequired: permissions.chatEdit,
  //   exact: true,
  // },
  // {
  //   path: '/chat/:id',
  //   loader: () => import('src/view/chat/view/ChatViewPage'),
  //   permissionRequired: permissions.chatRead,
  //   exact: true,
  // },

  // {
  //   path: '/chat-log',
  //   loader: () => import('src/view/chatLog/list/ChatLogListPage'),
  //   permissionRequired: permissions.chatLogRead,
  //   exact: true,
  // },
  // {
  //   path: '/chat-log/new',
  //   loader: () => import('src/view/chatLog/form/ChatLogFormPage'),
  //   permissionRequired: permissions.chatLogCreate,
  //   exact: true,
  // },
  // {
  //   path: '/chat-log/importer',
  //   loader: () => import('src/view/chatLog/importer/ChatLogImporterPage'),
  //   permissionRequired: permissions.chatLogImport,
  //   exact: true,
  // },
  // {
  //   path: '/chat-log/:id/edit',
  //   loader: () => import('src/view/chatLog/form/ChatLogFormPage'),
  //   permissionRequired: permissions.chatLogEdit,
  //   exact: true,
  // },
  // {
  //   path: '/chat-log/:id',
  //   loader: () => import('src/view/chatLog/view/ChatLogViewPage'),
  //   permissionRequired: permissions.chatLogRead,
  //   exact: true,
  // },

  // {
  //   path: '/app',
  //   loader: () => import('src/view/app/list/AppListPage'),
  //   permissionRequired: permissions.appRead,
  //   exact: true,
  // },
  // {
  //   path: '/app/new',
  //   loader: () => import('src/view/app/form/AppFormPage'),
  //   permissionRequired: permissions.appCreate,
  //   exact: true,
  // },
  // {
  //   path: '/app/importer',
  //   loader: () => import('src/view/app/importer/AppImporterPage'),
  //   permissionRequired: permissions.appImport,
  //   exact: true,
  // },
  // {
  //   path: '/app/:id/edit',
  //   loader: () => import('src/view/app/form/AppFormPage'),
  //   permissionRequired: permissions.appEdit,
  //   exact: true,
  // },
  // {
  //   path: '/app/:id',
  //   loader: () => import('src/view/app/view/AppViewPage'),
  //   permissionRequired: permissions.appRead,
  //   exact: true,
  // },
].filter(Boolean);

const publicRoutes = [
  {
    path: '/ ',
    loader: () => import('src/view/guestSection/guestDashboard'),
  },
  {
    path: '/auth/signin',
    loader: () => import('src/view/auth/SigninPage'),
  },
  {
    path: '/auth/signup',
    loader: () => import('src/view/auth/SignupPage'),
  },
  {
    path: '/auth/forgot-password',
    loader: () => import('src/view/auth/ForgotPasswordPage'),
  },
].filter(Boolean);

const emptyTenantRoutes = [
  {
    path: '/auth/tenant',
    loader: () => import('src/view/auth/TenantPage'),
  },
].filter(Boolean);

const emptyPermissionsRoutes = [
  {
    path: '/auth/empty-permissions',
    loader: () => import('src/view/auth/EmptyPermissionsPage'),
  },
].filter(Boolean);

const emailUnverifiedRoutes = [
  {
    path: '/auth/email-unverified',
    loader: () => import('src/view/auth/EmailUnverifiedPage'),
  },
].filter(Boolean);

const simpleRoutes = [
  {
    path: '/auth/password-reset',
    loader: () => import('src/view/auth/PasswordResetPage'),
  },
  {
    path: '/auth/invitation',
    loader: () => import('src/view/auth/InvitationPage'),
  },
  {
    path: '/auth/verify-email',
    loader: () => import('src/view/auth/VerifyEmailPage'),
  },
  {
    path: '/403',
    loader: () => import('src/view/shared/errors/Error403Page'),
  },
  {
    path: '/500',
    loader: () => import('src/view/shared/errors/Error500Page'),
  },
  {
    path: '**',
    loader: () => import('src/view/shared/errors/Error404Page'),
  },
].filter(Boolean);

export default {
  privateRoutes,
  publicRoutes,
  emptyTenantRoutes,
  emptyPermissionsRoutes,
  emailUnverifiedRoutes,
  simpleRoutes,
};
