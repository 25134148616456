import list from 'src/modules/prompt/list/promptListReducers';
import form from 'src/modules/prompt/form/promptFormReducers';
import view from 'src/modules/prompt/view/promptViewReducers';
import destroy from 'src/modules/prompt/destroy/promptDestroyReducers';
import importerReducer from 'src/modules/prompt/importer/promptImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
