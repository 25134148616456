import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'prompt',
    label: i18n('entities.chat.fields.prompt'),
    schema: schemas.relationToOne(
      i18n('entities.chat.fields.prompt'),
      {},
    ),
  },
  {
    name: 'parameters',
    label: i18n('entities.chat.fields.parameters'),
    schema: schemas.string(
      i18n('entities.chat.fields.parameters'),
      {},
    ),
  },
];