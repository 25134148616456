import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'name',
    label: i18n('entities.prompt.fields.name'),
    schema: schemas.string(
      i18n('entities.prompt.fields.name'),
      {},
    ),
  },
  {
    name: 'description',
    label: i18n('entities.prompt.fields.description'),
    schema: schemas.string(
      i18n('entities.prompt.fields.description'),
      {},
    ),
  },
  {
    name: 'app',
    label: i18n('entities.prompt.fields.app'),
    schema: schemas.relationToOne(
      i18n('entities.prompt.fields.app'),
      {},
    ),
  },
  {
    name: 'prompt',
    label: i18n('entities.prompt.fields.prompt'),
    schema: schemas.string(
      i18n('entities.prompt.fields.prompt'),
      {},
    ),
  },
  {
    name: 'isEnabled',
    label: i18n('entities.prompt.fields.isEnabled'),
    schema: schemas.boolean(
      i18n('entities.prompt.fields.isEnabled'),
      {},
    ),
  },
  {
    name: 'isPublic',
    label: i18n('entities.prompt.fields.isPublic'),
    schema: schemas.boolean(
      i18n('entities.prompt.fields.isPublic'),
      {},
    ),
  },
  {
    name: 'starCount',
    label: i18n('entities.prompt.fields.starCount'),
    schema: schemas.integer(
      i18n('entities.prompt.fields.starCount'),
      {},
    ),
  },
  {
    name: 'usageCount',
    label: i18n('entities.prompt.fields.usageCount'),
    schema: schemas.integer(
      i18n('entities.prompt.fields.usageCount'),
      {},
    ),
  },
];