import { connectRouter } from "connected-react-router";
import layout from "src/modules/layout/layoutReducers";
import auth from "src/modules/auth/authReducers";
import tenant from "src/modules/tenant/tenantReducers";
import plan from "src/modules/plan/planReducers";
import user from "src/modules/user/userReducers";
import auditLog from "src/modules/auditLog/auditLogReducers";
import settings from "src/modules/settings/settingsReducers";
import userSettings from "src/modules/userSettings/userSettingsReducers";
import prompt from "src/modules/prompt/promptReducers";
import chat from "src/modules/chat/chatReducers";
import chatGPT from "src/modules/chatGPT/gptReducers";
import chatLog from "src/modules/chatLog/chatLogReducers";
import app from "src/modules/app/appReducers";
import { combineReducers } from "redux";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    layout,
    auth,
    tenant,
    plan,
    user,
    auditLog,
    settings,
    userSettings,
    prompt,
    chat,
    chatGPT,
    chatLog,
    app,
  });
