import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/chatLog/importer/chatLogImporterSelectors';
import ChatLogService from 'src/modules/chatLog/chatLogService';
import fields from 'src/modules/chatLog/importer/chatLogImporterFields';
import { i18n } from 'src/i18n';

const chatLogImporterActions = importerActions(
  'CHATLOG_IMPORTER',
  selectors,
  ChatLogService.import,
  fields,
  i18n('entities.chatLog.importer.fileName'),
);

export default chatLogImporterActions;