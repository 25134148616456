import list from 'src/modules/chat/list/chatListReducers';
import form from 'src/modules/chat/form/chatFormReducers';
import view from 'src/modules/chat/view/chatViewReducers';
import destroy from 'src/modules/chat/destroy/chatDestroyReducers';
import importerReducer from 'src/modules/chat/importer/chatImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
