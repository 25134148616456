import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/app/importer/appImporterSelectors';
import AppService from 'src/modules/app/appService';
import fields from 'src/modules/app/importer/appImporterFields';
import { i18n } from 'src/i18n';

const appImporterActions = importerActions(
  'APP_IMPORTER',
  selectors,
  AppService.import,
  fields,
  i18n('entities.app.importer.fileName'),
);

export default appImporterActions;