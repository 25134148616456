import ChatService from 'src/modules/chat/chatService';
import Errors from 'src/modules/shared/error/errors';
// import Message from "src/view/shared/message";
// import { getHistory } from "src/modules/store";
// import { i18n } from "src/i18n";

const prefix = 'CHAT_GPT';

const chatGPTActions = {
  //   INIT_STARTED: `${prefix}_INIT_STARTED`,
  //   INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  //   INIT_ERROR: `${prefix}_INIT_ERROR`,

  INIT_TOKEN_STARTED: `${prefix}_INIT_TOKEN_STARTED`,
  INIT_TOKEN_ERROR: `${prefix}_INIT_TOKEN_ERROR`,
  INIT_TOKEN_SUCCESS: `${prefix}_INIT_TOKEN_SUCCESS`,
  INIT_TOKEN_CLEAR: `${prefix}_INIT_TOKEN_CLEAR`,

  doClearToken: () => async (dispatch) => {
    try {
      dispatch({
        type: chatGPTActions.INIT_TOKEN_CLEAR,
      });
      //   getHistory().push('/chat');
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: chatGPTActions.INIT_TOKEN_ERROR,
      });
    }
  },
  doInitToken: (values) => async (dispatch) => {
    try {
      dispatch({
        type: chatGPTActions.INIT_TOKEN_STARTED,
      });
      const record = await ChatService.initChatGPT(values);

      dispatch({
        type: chatGPTActions.INIT_TOKEN_SUCCESS,
        payload: record,
      });

      //   Message.success(
      //     i18n('entities.chat.create.success'),
      //   );

      //   getHistory().push('/chat');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: chatGPTActions.INIT_TOKEN_ERROR,
      });
    }
  },
};

export default chatGPTActions;
